// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}









































































































































.pager {
  &__next {
    a {
      transform: rotate(180deg);
    }
  }

  & > ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      transition: 0.25s ease;
      transition-property: background, opacity;

      & + li {
        margin-left: 10px;
      }

      &[disabled] {
        opacity: 0.3;

        & > a {
          cursor: default;

          &:hover {
            background: transparent;

            svg {
              color: #333;
            }
          }
        }
      }
      & > a {
        display: inline-block;
        min-width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 16px;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        border-radius: 4px;
        background: transparent;
        color: #333;
        cursor: pointer;
        text-decoration: none;
        transition: 0.25s ease;
        transition-property: background, opacity;

        svg {
          transition: 0.25s ease;
          transition-property: color;
        }

        &:hover {
          background: #e0dfee;

          svg {
            color: #6f61e9;
          }
        }

        &.active {
          background: linear-gradient(268.74deg, #7a6afa 1.17%, #607aff 99.07%);
          color: #fff;
        }

        &.disabled {
          cursor: default;

          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}
